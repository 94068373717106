import { Row } from 'antd';
import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TourClickedIndex } from 'src/tourGuide/guideWidgetClickAction';

import Black from '../../../../../../assets/images/black.png';
import Check from '../../../../../../assets/images/green.png';
import Close from '../../../../../../assets/images/cancel.png';

const QuickSetUpWidget = () => {
  const rowRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const [isVisible, setIsVisible] = useState(
    localStorage.getItem('isVisible') === 'true',
  );
  const [flag0, setFlag0] = useState<boolean>(false);
  const [flag1, setFlag1] = useState<boolean>(false);
  const [flag2, setFlag2] = useState<boolean>(false);
  const [flag3, setFlag3] = useState<boolean>(false);
  const [flag4, setFlag4] = useState<boolean>(false);
  const [flag5, setFlag5] = useState<boolean>(false);
  const [flag6, setFlag6] = useState<boolean>(false);

  const state = [
    {
      title: 'Upload your data',
      checked: flag0,
    },
    {
      title: 'Ask question and build chart',
      checked: flag1,
    },
    {
      title: 'Create your first dashboard',
      checked: flag2,
    },
    {
      title: 'Setup an email report',
      checked: flag3,
    },
    {
      title: 'Invite teammates',
      checked: flag4,
    },
    {
      title: 'Teach AI to improve the visualization',
      checked: flag5,
    },
    {
      title: 'Teach AI how to answer questions',
      checked: flag6,
    },
  ];

  const backgroundColor =
    'linear-gradient(60deg, rgba(35, 140, 246, 0.5),rgba(35, 140, 246, 0.32),rgba(255, 199, 0, 0.15),#FFC700,rgba(21, 180, 4, 0.32),rgba(21, 180, 4, 0.5))';

  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const clickedIndex = useSelector((state: any) => state.guideWidget.index);

  useEffect(() => {
    setFlag0(localStorage.getItem('key[0]') === 'true');
    setFlag1(localStorage.getItem('key[1]') === 'true');
    setFlag2(localStorage.getItem('key[2]') === 'true');
    setFlag3(localStorage.getItem('key[3]') === 'true');
    setFlag4(localStorage.getItem('key[4]') === 'true');
    setFlag5(localStorage.getItem('key[5]') === 'true');
    setFlag6(localStorage.getItem('key[6]') === 'true');
  }, [clickedIndex]);

  useEffect(() => {
    localStorage.setItem('isVisible', isVisible.toString());
  }, [isVisible]);

  const handleCancel = () => {
    setIsVisible(!isVisible);
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    setIsDragging(true);
    if (rowRef.current) {
      setStartX(e.pageX - rowRef.current.offsetLeft);
      setScrollLeft(rowRef.current.scrollLeft);
    }
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDragging) {
      return;
    }

    e.preventDefault();

    if (rowRef.current) {
      const x = e.pageX - rowRef.current.offsetLeft;
      const delta = x - startX;
      rowRef.current.scrollLeft = scrollLeft - delta;
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleClick = (index: number) => {
    dispatch(TourClickedIndex(index));
  };

  return (
    <>
      {!isVisible ? (
        <div
          style={{
            width: '-webkit-fill-available',
            backgroundImage: backgroundColor,
          }}
          className="h-fit relative p-5 rounded-lg mt-0 mr-0 mb-6 ml-0"
        >
          <button
            type="button"
            onClick={handleCancel}
            className="p-0 bg-transparent border-none right-[10px] top-[10px] absolute cursor-pointer"
          >
            <img src={Close} alt="Cancel" width="25px" height="25px" />
          </button>
          <p
            style={{
              lineHeight: '160%',
              fontFamily: 'Inter',
            }}
            className="text-black text-[20px] font-semibold not-italic"
          >
            Go through a quick setup
          </p>
          <p
            style={{
              lineHeight: '150%',
              fontFamily: 'Inter',
            }}
            className="text-black text-[16px] font-normal not-italic"
          >
            Not sure where to start? Check out our suggestions for getting your
            first dashboard up and running.
          </p>
          <Row
            style={{
              flexWrap: 'nowrap',
            }}
            className="mt-[25px] flex overflow-x-hidden cursor-grab"
            ref={rowRef}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
          >
            {state.map((item, index) => (
              <div
                role="button"
                tabIndex={0}
                className="pt-[13px] pb-[13px] pl-[14px] pr-[14px] mr-2 rounded-lg border border-[#D9D9D9] bg-white justify-center items-center inline-flex max-h-[50px] relative"
                key={index}
                onClick={() => handleClick(index)}
              >
                <span
                  style={{
                    lineHeight: '150%',
                    fontFamily: 'Inter',
                    whiteSpace: 'nowrap',
                  }}
                  className="text-black text-[13px] font-normal not-italic flex"
                >
                  {item.title}
                </span>
                <img
                  src={item.checked === true ? Check : Black}
                  alt="check"
                  className="ml-[5px]"
                />
              </div>
            ))}
          </Row>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default QuickSetUpWidget;
